import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import ColorList from 'components/ColorList';
import SEO from 'components/seo';
import get from 'lodash.get';
import AuthContext from '../provider/AuthContext';

function getColorProducts(colorList, googleSheetProducts) {
    let productItems = [];
    if (typeof colorList.products !== 'undefined') {
        colorList.products.forEach(product => {
            let addProduct = false;
            product.variants.forEach(variant => {
                let productInfo = googleSheetProducts.nodes.find(
                    googleProduct =>
                        googleProduct.sku === parseInt(variant.sku) &&
                        googleProduct.caAssortment === 'Y'
                );
                if (productInfo) {
                    addProduct = true;
                }
            });

            if (addProduct) {
                productItems.push(product);
            }
        });

        colorList.products = productItems;
    }

    return colorList;
}

function getContentfulProduct(color, allContentfulProduct) {
    let collection = {
        products: [],
    };

    color.products.forEach(collectionProduct => {
        let contentfulProduct = allContentfulProduct.nodes.find(
            product =>
                product.shopifyProduct.handle === collectionProduct.handle
        );

        let finalProduct = {
            title: get(contentfulProduct, 'productItem.title'),
            images: get(contentfulProduct, 'productItem.images'),
            handle: collectionProduct.handle,
            tags: collectionProduct.tags,
            variants: collectionProduct.variants,
        };
        collection.products.push(finalProduct);
    });

    return collection;
}

export default function ColorListPage(props) {
    let {
        data: {
            allContentfulProductColor,
            allContentfulColorCategory,
            demi,
            demiContentful,
            permanent,
            blonde,
            stylist,
            contentfulColorLanding,
            rapidToners,
            lighteners,
            supportTools,
            developers,
            creatives,
            allGoogleSheetProductsRow,
            allContentfulProduct,
        },
    } = props;

    // filter the products accoring to the region
    let storedRegion = '';
    if (typeof window !== 'undefined') {
        storedRegion = localStorage.getItem('preferred-region');
    }

    // Go through each set of products and find the product in contentful
    let demiCollection = getContentfulProduct(demi, allContentfulProduct);

    if (storedRegion !== 'CA') {
        return (
            <Layout>
                <AuthContext.Consumer>
                    {auth => (
                        <ColorList
                            header={contentfulColorLanding}
                            colors={allContentfulProductColor.nodes}
                            demi={getContentfulProduct(
                                demi,
                                allContentfulProduct
                            )}
                            permanent={getContentfulProduct(
                                permanent,
                                allContentfulProduct
                            )}
                            blonde={getContentfulProduct(
                                blonde,
                                allContentfulProduct
                            )}
                            stylist={getContentfulProduct(
                                stylist,
                                allContentfulProduct
                            )}
                            rapidToners={getContentfulProduct(
                                rapidToners,
                                allContentfulProduct
                            )}
                            lighteners={getContentfulProduct(
                                lighteners,
                                allContentfulProduct
                            )}
                            creatives={getContentfulProduct(
                                creatives,
                                allContentfulProduct
                            )}
                            supportTools={getContentfulProduct(
                                supportTools,
                                allContentfulProduct
                            )}
                            developers={getContentfulProduct(
                                developers,
                                allContentfulProduct
                            )}
                            contentfulCategories={
                                allContentfulColorCategory.nodes
                            }
                            auth={auth}
                        />
                    )}
                </AuthContext.Consumer>
            </Layout>
        );
    } else {
        let demiList = getColorProducts(
            getContentfulProduct(demi, allContentfulProduct),
            allGoogleSheetProductsRow
        );
        let permanentList = getColorProducts(
            getContentfulProduct(permanent, allContentfulProduct),
            allGoogleSheetProductsRow
        );
        let blondeList = getColorProducts(
            getContentfulProduct(blonde, allContentfulProduct),
            allGoogleSheetProductsRow
        );
        let stylistList = getColorProducts(
            getContentfulProduct(stylist, allContentfulProduct),
            allGoogleSheetProductsRow
        );
        let rapidTonersList = getColorProducts(
            getContentfulProduct(rapidToners, allContentfulProduct),
            allGoogleSheetProductsRow
        );
        let lightenersList = getColorProducts(
            getContentfulProduct(lighteners, allContentfulProduct),
            allGoogleSheetProductsRow
        );
        let creativesList = getColorProducts(
            getContentfulProduct(creatives, allContentfulProduct),
            allGoogleSheetProductsRow
        );
        let supportToolsList = getColorProducts(
            getContentfulProduct(supportTools, allContentfulProduct),
            allGoogleSheetProductsRow
        );
        let developersList = getColorProducts(
            getContentfulProduct(developers, allContentfulProduct),
            allGoogleSheetProductsRow
        );

        return (
            <Layout>
                <SEO
                    description={get(
                        contentfulColorLanding,
                        'seoListing.description.description'
                    )}
                    title={get(contentfulColorLanding, 'seoListing.seoTitle')}
                    slug={'color-list'}
                />
                <ColorList
                    header={contentfulColorLanding}
                    colors={allContentfulProductColor.nodes}
                    demi={demiList}
                    permanent={permanentList}
                    blonde={blondeList}
                    stylist={stylistList}
                    rapidToners={rapidTonersList}
                    lighteners={lightenersList}
                    creatives={creativesList}
                    supportTools={supportToolsList}
                    developers={developersList}
                    contentfulCategories={allContentfulColorCategory.nodes}
                />
            </Layout>
        );
    }
}

export const query = graphql`
    query($locale: String) {
        allContentfulProductColor(filter: { node_locale: { eq: $locale } }) {
            nodes {
                ...ContentfulProductColorFields
            }
        }
        allContentfulColorCategory(filter: { node_locale: { eq: $locale } }) {
            nodes {
                ...ContentfulColorCategoryFields
            }
        }
        contentfulColorLanding(node_locale: { eq: $locale }) {
            colorListHeader
            colorListSubtitle
            colorListBannerImage {
                localFile {
                    publicURL
                }
            }
            colorListImageIsDark
            node_locale
            seoListing {
                ...ContentfulSeoListingFields
            }
        }
        demi: shopifyCollection(title: { eq: "Kenra Color Demi" }) {
            products {
                handle
                tags
                variants {
                    sku
                }
            }
        }
        demiContentful: contentfulCollection(
            handle: { eq: "kenra-color-demi-permanent" }
            node_locale: { eq: $locale }
        ) {
            title
            handle
            description {
                description
            }
        }
        permanent: shopifyCollection(title: { eq: "Kenra Color Permanent" }) {
            title
            products {
                title
                # TODO: Restore Ticket 101
                # images {
                #     localFile {
                #         publicURL
                #         thumbnail: childImageSharp {
                #             resize(width: 100, height: 100) {
                #                 src
                #             }
                #         }
                #     }
                # }
                handle
                tags
                variants {
                    sku
                }
            }
        }
        blonde: shopifyCollection(title: { eq: "Kenra Color Simply Blonde" }) {
            title
            products {
                title
                # TODO: restore, ticket 101
                # images {
                #     localFile {
                #         publicURL
                #         thumbnail: childImageSharp {
                #             resize(width: 100, height: 100) {
                #                 src
                #             }
                #         }
                #     }
                # }
                handle
                tags
                variants {
                    sku
                }
            }
        }
        stylist: shopifyCollection(
            title: { eq: "Kenra Color Studio Stylist Express" }
        ) {
            title
            products {
                title
                # TODO: restore, ticket 101
                # images {
                #     localFile {
                #         publicURL
                #         thumbnail: childImageSharp {
                #             resize(width: 100, height: 100) {
                #                 src
                #             }
                #         }
                #     }
                # }
                handle
                tags
                variants {
                    sku
                }
            }
        }
        rapidToners: shopifyCollection(title: { eq: "Rapid Toners" }) {
            title
            products {
                title
                # TODO: restore, ticket 101
                # images {
                #     localFile {
                #         publicURL
                #         thumbnail: childImageSharp {
                #             resize(width: 100, height: 100) {
                #                 src
                #             }
                #         }
                #     }
                # }
                handle
                tags
                variants {
                    sku
                }
            }
        }
        lighteners: shopifyCollection(title: { eq: "Lighteners" }) {
            title
            products {
                title
                # TODO: restore, ticket 101
                # images {
                #     localFile {
                #         publicURL
                #         thumbnail: childImageSharp {
                #             resize(width: 100, height: 100) {
                #                 src
                #             }
                #         }
                #     }
                # }
                handle
                tags
                variants {
                    sku
                }
            }
        }
        creatives: shopifyCollection(title: { eq: "Kenra Color Creatives" }) {
            title
            products {
                title
                # TODO: restore, ticket 101
                # images {
                #     localFile {
                #         publicURL
                #         thumbnail: childImageSharp {
                #             resize(width: 100, height: 100) {
                #                 src
                #             }
                #         }
                #     }
                # }
                handle
                tags
                variants {
                    sku
                }
            }
        }
        supportTools: shopifyCollection(
            title: { eq: "Kenra Color Support Products" }
        ) {
            title
            products {
                title
                # TODO: restore, ticket 101
                # images {
                #     localFile {
                #         publicURL
                #         thumbnail: childImageSharp {
                #             resize(width: 100, height: 100) {
                #                 src
                #             }
                #         }
                #     }
                # }
                handle
                tags
                variants {
                    sku
                }
            }
        }
        developers: shopifyCollection(
            title: { eq: "Kenra Color Permanent Developers" }
        ) {
            title
            products {
                title
                handle
                tags
                variants {
                    sku
                }
            }
        }
        allGoogleSheetProductsRow {
            nodes {
                sku
                caAssortment
            }
        }
        allContentfulProduct(filter: { node_locale: { eq: $locale } }) {
            nodes {
                title
                shopifyProduct {
                    handle
                }
                productItem {
                    title
                    images {
                        id
                        localFile {
                            publicURL
                            thumbnail: childImageSharp {
                                resize(width: 100, height: 100) {
                                    src
                                }
                            }
                        }
                    }
                }
                node_locale
            }
        }
    }
`;
